<template>
  <Container>
    <v-card-text>
      <v-form @submit.prevent="register">
        <v-text-field
          v-model="name"
          outlined
          label="Họ và tên"
          :error-messages="nameError"
          :hide-details="nameError===''"
          class="mb-3"
        ></v-text-field>

        <v-text-field
          v-model="email"
          outlined
          label="Email"
          :error-messages="emailError"
          :hide-details="emailError===''"
          class="mb-3"
        ></v-text-field>

        <v-text-field
          v-model="phone"
          v-mask="'####-###-###'"
          outlined
          label="Số điện thoại"
          :error-messages="phoneError"
          :hide-details="phoneError===''"
          class="mb-3"
        ></v-text-field>

        <v-text-field
          v-model="password"
          outlined
          :type="isPasswordVisible ? 'text' : 'password'"
          label="Mật khẩu"
          :append-icon="isPasswordVisible ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
          :error-messages="passwordError"
          :hide-details="passwordError===''"
          @click:append="isPasswordVisible = !isPasswordVisible"
        ></v-text-field>

        <div
          v-if="success"
          class="d-flex justify-center mt-5"
        >
          <v-icon
            color="success"
            large
            left
          >
            mdi-check-circle-outline
          </v-icon>
          <span class="text-subtitle-2">Đăng ký thành công. Vui lòng kiểm tra email của bạn để xác nhận tài khoản.</span>
        </div>

        <v-btn
          v-if="!success"
          block
          color="primary"
          class="mt-6"
          type="submit"
          :disabled="loading"
        >
          <v-progress-circular
            v-if="loading"
            :size="14"
            :width="2"
            indeterminate
          />
          <span v-else>{{ $t('register') }}</span>
        </v-btn>
      </v-form>
    </v-card-text>

    <!-- login  -->
    <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
      <span class="me-2">
        Bạn đã có tài khoản?
      </span>
      <router-link :to="{ name:'login' }">
        Đăng nhập
      </router-link>
    </v-card-text>
  </Container>
</template>

<script>

import Container from '../components/Container.vue'

export default {
  components: {
    Container,
  },

  data() {
    return {
      errors: {},
      message: '',
      success: false,
      loading: false,
      name: '',
      email: '',
      phone: '',
      password: '',
      isPasswordVisible: false,
    }
  },

  computed: {
    nameError() {
      return this.errors.name || ''
    },
    emailError() {
      return this.errors.email || ''
    },
    phoneError() {
      return this.errors.phone || ''
    },
    passwordError() {
      return this.errors.password || ''
    },
  },

  methods: {
    register() {
      this.message = ''
      this.errors = {}
      this.loading = true

      const {
        name, email, phone, password,
      } = this
      this.$http.post('/api/auth/user/register', {
        name, email, phone, password,
      })
        .then(() => {
          this.success = true
        })
        .catch(err => {
          if (err.response.data.errors) {
            this.errors = err.response.data.errors
          } else if (err.response.data.message) {
            this.message = err.response.data.message
          } else {
            this.message = 'Something is wrong!'
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
